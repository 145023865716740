import React from 'react';
import { IconType } from '@iconscout/react-unicons';

import UilIcon from '../components/UilIcon';

const colors = {
	info: 'bg-info text-white border-info-hdr',
	danger: 'bg-danger text-white border-danger-hdr',
	success: 'bg-success text-white border-success-hdr',
	warning: 'bg-warning text-white border-warning-hdr',
};

const icons: { [key: string]: IconType } = {
	info: 'UilExclamationCircle',
	danger: 'UilExclamationOctagon',
	success: 'UilCheckCircle',
	warning: 'UilExclamationTriangle',
};

interface AlertProps {
	type: 'info' | 'danger' | 'success' | 'warning';
	title: string;
	message?: string;
}

export default function Alert({ type, title, message }: AlertProps) {
	return (
		<div className={`px-4 py-2 rounded-lg border-[0.5px] ${colors[type]}`}>
			<div className="flex items-center gap-4">
				<span className="">
					<UilIcon type={icons[type]} size={18} />
				</span>
				<div>
					<h6 className="font-bold text-sm">{title}</h6>
					<span className="font-normal text-sm">{message}</span>
				</div>
			</div>
		</div>
	);
}
