/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Button from '../components/Button';
import { useProfile } from '../hooks/useProfile';
import { TenantRole } from '../context/ProfileContext';
import { useNavigate } from 'react-router-dom';
import { UilCheckCircle, UilServer, UilServerNetworkAlt } from '@iconscout/react-unicons';

export default function TenantMenu() {
	const navigate = useNavigate();
	const { tenant, tenants, setTenant } = useProfile();
	const [open, setOpen] = useState(false);
	const userMenuRef = useRef<HTMLInputElement>(null);

	function handleSetTenant(tenant: TenantRole) {
		navigate('/');
		setTenant(tenant.id);
		setOpen(false);
	}

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
				setOpen(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [userMenuRef, setOpen]);

	return (
		<div ref={userMenuRef} className="relative">
			<Button
				text={`${tenant?.name || ''}`}
				variant="extraLight"
				style="roundedOutline"
				icon="UilServerConnection"
				onClick={() => setOpen(!open)}
			/>
			{tenants && tenants.length > 0 && (
				<div
					className={`absolute z-[1000] m-0 ${!open && 'hidden'} mt-2 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg`}
				>
					<ul className="block bg-white shadow-md rounded-4 overflow-hidden">
						{tenants.map((item) => (
							<li key={item.id} className="bg-white hover:bg-primary/10">
								<button className="px-3 py-1.5 text-sm text-dark" onClick={() => handleSetTenant(item)}>
									<span className="flex gap-2 items-center">
										<UilCheckCircle
											className={`text-light-extra w-4 ${item.subscription ? 'text-success' : ''}`}
										/>{' '}
										{item.name}
									</span>
								</button>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
}
