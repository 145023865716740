import React from 'react';

import WrapperPage from './WrapperPage';
import WhiteBox from '../components/WhiteBox';
import { UilInfoCircle } from '@iconscout/react-unicons';

export default function ISPImportPage() {
	return (
		<WrapperPage title="ISP Import Tool" info="The import has been moved to another page.">
			<div className="m-4">
				<WhiteBox>
					<p>
						<div className="flex gap-2 items-center">
							<UilInfoCircle className="text-info" />
							<span>
								To access the new import, select <span className="font-bold">ISP</span> in the header
								and navigate to
								<span className="font-bold"> Administration {'->'} Import</span> menu.
							</span>
						</div>
					</p>
				</WhiteBox>
			</div>
		</WrapperPage>
	);
}
