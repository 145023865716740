import { TenantRole } from '../../context/ProfileContext';
import config from '../../shared/config';
import { useCallback } from 'react';
import { APIError, ValidationError } from '../../shared/api_errors';

const useAPI = (auth: boolean = false, token?: string, tenant?: TenantRole) => {
	const fetchApi = useCallback(
		async (
			endpoint: RequestInfo | URL,
			options: { method?: string; headers?: Record<string, string>; body?: unknown } = {},
		) => {
			const headers: Record<string, string> = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...options.headers,
			};

			if (auth) {
				if (token) headers.authorization = `Bearer ${token}`;
				else return;
			}

			if (tenant) {
				headers['X-Tenant-ID'] = tenant.id;
			}

			const url = `${config.api.url}${endpoint}`;

			const response = await fetch(url, {
				method: options.method || 'GET',
				headers,
				body: options.body ? JSON.stringify(options.body) : null,
			});

			if (!response.ok) {
				const errorResponse = await response.json();
				const error = errorResponse.detail;
				// Assume it's a Pydantic validation error
				if (
					Array.isArray(error) &&
					error.every((e: ValidationError) => 'loc' in e && 'msg' in e && 'type' in e)
				) {
					throw new APIError('Validation Error', response.status, error as ValidationError[]);
				} else {
					throw new APIError(error, response.status);
				}
			}

			try {
				// Try to parse the response as JSON
				const json = await response.json();
				return json;
			} catch (error) {
				// If parsing fails, return undefined
				return;
			}
		},
		[token, tenant],
	);

	const get = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'GET' });
		},
		[token, tenant],
	);

	const post = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'POST' });
		},
		[token, tenant],
	);

	const put = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'PUT' });
		},
		[token, tenant],
	);

	const del = useCallback(
		(endpoint: string, options = {}) => {
			return fetchApi(endpoint, { ...options, method: 'DELETE' });
		},
		[token, tenant],
	);

	return { get, post, put, del };
};

export default useAPI;
