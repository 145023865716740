/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../slices/Breadcrumbs';
import Select from '../components/Select';
import useCRUDQuery from '../hooks/api/useCRUDQuery';
import { ISP } from '../shared/models/ISP';
import StickyBottom from '../components/StickyBottom';
import Button from '../components/Button';
import DataTable, { DataTableField } from '../components/DataTable';
import { NAS, NASConnectionResponse } from '../shared/models/infra/NAS';
import WhiteBox from '../components/WhiteBox';

import { UilServerNetwork } from '@iconscout/react-unicons';
import useAPINAS from '../hooks/api/useAPINASRouterOS';
import useAPIISPMigration, { ImportTXTPayload } from '../hooks/api/useAPIISPImport';
import { Currency } from '../shared/models/currency';
import Logs from '../components/Logs';
import ConfimationBox from '../slices/ConfirmationBox';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useCRUDOperations from '../hooks/api/useCRUDOperations';
import Input from '../components/Input';
import WrapperPage from './WrapperPage';

export default function ImportTxtPage() {
	const navigate = useNavigate();
	const { isp_id, nas_id, currency_id } = useParams();

	const [isp, setISP] = useState<ISP | undefined>();
	const [nas, setNAS] = useState<NAS | undefined>();
	const [currency, setCurrency] = useState<Currency | undefined>();
	const [form, setForm] = useState({ pools: '', profiles: '', secrets: '' });

	const { get: getISP } = useCRUDOperations('/isp');
	const { get: getNAS } = useCRUDOperations('/infra/nas');
	const { get: getCurrency } = useCRUDOperations('/currency');

	const { importLogs, fetchImportTXT, importLoading } = useAPIISPMigration();

	function handleImport() {
		if (!isp) return;
		if (!nas) return;
		if (!currency) return;

		const payload: ImportTXTPayload = {
			currency_id: currency.id,
			nas_id: nas.id,
			pools: form.pools,
			profiles: form.profiles,
			secrets: form.secrets,
		};

		fetchImportTXT(isp.id, payload);
	}

	function handleBack() {
		navigate('/import');
	}

	useEffect(() => {
		isp_id && getISP(isp_id).then((isp) => setISP(isp));
		nas_id && getNAS(nas_id).then((nas) => setNAS(nas));
		currency_id && getCurrency(currency_id).then((currency) => setCurrency(currency));
	}, []);

	return (
		<WrapperPage title="ISP Import Tool">
			<div>
				<div className="m-4 flex flex-col gap-4">
					<WhiteBox>
						<pre>
							ISP: {isp && isp.name} {'\n'}
							NAS: {nas && nas.name} {'\n'}
							Currency: {currency && currency.name}
						</pre>
					</WhiteBox>
					<WhiteBox className="h-64 flex flex-col gap-2">
						Pools:
						<pre className="bg-gray-100 p-2 rounded-lg">/ip/pool export file=ip-pools terse</pre>
						<div className="flex flex-grow">
							<Input
								multiline
								full
								value={form.pools}
								onChange={(value) => setForm({ ...form, pools: value })}
							/>
						</div>
					</WhiteBox>
					<WhiteBox className="h-64 flex flex-col gap-2">
						Pools:
						<pre className="bg-gray-100 p-2 rounded-lg">/ppp/profile export file=ppp-profiles terse</pre>
						<div className="flex flex-grow">
							<Input
								multiline
								full
								value={form.profiles}
								onChange={(value) => setForm({ ...form, profiles: value })}
							/>
						</div>
					</WhiteBox>
					<WhiteBox className="h-96 flex flex-col gap-2">
						Secrets:
						<pre className="bg-gray-100 p-2 rounded-lg">
							# Router OS (latest) {'\n'}
							/ppp/secret export file=ppp-secret show-sensitive terse
						</pre>
						<pre className="bg-gray-100 p-2 rounded-lg">
							# Router OS 6 {'\n'}
							/ppp/secret export file=ppp-secret terse
						</pre>
						<div className="flex flex-grow">
							<Input
								multiline
								full
								value={form.secrets}
								onChange={(value) => setForm({ ...form, secrets: value })}
							/>
						</div>
					</WhiteBox>
					{(importLoading || importLogs) && (
						<WhiteBox className={importLoading ? 'animate-pulse' : ''}>
							<Logs logs={importLogs?.map((line) => `${line}\n`) || ['INFO: Importing...']} />
						</WhiteBox>
					)}
				</div>
			</div>
			<StickyBottom>
				<div className="w-full flex justify-between">
					<Button
						text="Import"
						variant="danger"
						icon={importLoading ? { type: 'UilSpinner', spin: true } : 'UilImport'}
						onClick={handleImport}
						style="roundedOutline"
						disabled={importLoading || !isp || !nas || !currency}
					/>
					<Button text="Back" variant="link" onClick={handleBack} />
				</div>
			</StickyBottom>
		</WrapperPage>
	);
}
