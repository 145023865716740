import { useCallback } from 'react';

function useFeedbackVanishing(onVanish: () => void, cooldown: number = 3000) {
	const triggerFeedback = useCallback(() => {
		// Set a timeout to hide the feedback after the cooldown period
		const timeout = setTimeout(() => {
			onVanish();
		}, cooldown);

		// Cleanup timeout if the component is unmounted before the timer expires
		return () => clearTimeout(timeout);
	}, [cooldown]);

	return { triggerFeedback };
}

export default useFeedbackVanishing;
