import React, { ReactNode, useEffect } from 'react';

import Breadcrumbs from '../slices/Breadcrumbs';
import Alert from '../slices/Alert';
import { APIError, APIErrorClass } from '../shared/api_errors';
import useTimeoutVisibility from '../hooks/useTimeoutVisibility';

interface WrapperPageProps {
	title: string;
	success?: { title: string; message: string };
	onSuccessVanish?: () => void;
	error?: APIError;
	info?: string;
	children: ReactNode;
}

export default function WrapperPage({ title, error, info, success, onSuccessVanish, children }: WrapperPageProps) {
	function handleOnVanish() {
		onSuccessVanish && onSuccessVanish();
	}

	const { triggerFeedback } = useTimeoutVisibility(handleOnVanish);

	useEffect(() => {
		triggerFeedback();
	}, [success]);

	return (
		<section className="flex flex-col flex-grow w-full">
			<div className="m-4 flex flex-col gap-4">
				<div className="mb-4 flex items-center justify-between">
					<div>
						<h1 className="text-xl font-bold">{title}</h1>
					</div>
					<div className="flex flex-wrap justify-center">
						<Breadcrumbs />
					</div>
				</div>
				{!error && success && <Alert type="success" title={success.title} message={success.message} />}
				{!error && !success && info && <Alert type="info" title="Information" message={info} />}
				{error && (
					<Alert
						type={error.error_type() === APIErrorClass.InternalError ? 'danger' : 'warning'}
						title={
							error.error_type() === APIErrorClass.InternalError
								? 'Ops! Something went wrong.'
								: 'Oh, no! Validation errors found.'
						}
						message={
							error.error_type() === APIErrorClass.InternalError
								? 'An internal problem occurred and it was not possible to process your request.'
								: error.message
						}
					/>
				)}
			</div>
			<div className="flex flex-col gap-4 flex-grow">{children}</div>
		</section>
	);
}
