import { useState } from 'react';

import useCRUDQuery, { Condition, OrCondition, SortOrder } from '../hooks/api/useCRUDQuery';
import useCRUDOperations from '../hooks/api/useCRUDOperations';
import { APIError } from '../shared/api_errors';
import { Filter, isOr } from '../slices/Filters';

export interface CRUDPageHookProps {
	endpoint: string;
	initialSortField?: string;
	initialSortOrder?: SortOrder;
	usePagination?: boolean;
	useSorting?: boolean;
	filters?: Array<Filter>;
}

export interface CRUDPageHookReturn<T> {
	data: T[];
	fields: Array<{ field: keyof T; title: string; sortable?: boolean }>;
	actions: Array<{ title: string; icon: string; action: (row: T) => void }>;
	error?: APIError;
	loading: boolean;
	page: number;
	pageSize: number;
	totalRecords: number;
	sortField?: string;
	sortOrder: SortOrder;
	refetch: () => Promise<void>;
	handleOnPageSize: (pageSize: number) => void;
	handleOnPage: (page: number) => void;
	handleOnRefresh: () => void;
	handleOnSort: (newSortField: string, newSortOrder: SortOrder) => void;
	handleOnValueChange: (id: string, value?: string) => void;
	handleOnOperatorChange: (condition: Condition | OrCondition) => void;
	handleRemove: (id: string) => void;
}

export default function useCRUDPage<T extends { id: string }>({
	endpoint,
	initialSortField,
	initialSortOrder = 'asc',
	usePagination = true,
	useSorting = true,
	filters,
}: CRUDPageHookProps): CRUDPageHookReturn<T> {
	const data = useCRUDQuery<T>(endpoint, { initialSortField, initialSortOrder, usePagination, useSorting });
	const { remove, error: crudOperationsError } = useCRUDOperations(endpoint);

	const error: APIError | undefined = data.error || crudOperationsError;

	const [filterValues, setFilterValues] = useState<{ [key: string]: string }>({});

	async function handleRemove(id: string) {
		await remove(id);
		data.refetch();
	}

	if (filters) {
		for (const filter of filters) {
			filter.value = filterValues[filter.id] || '';
		}
	}

	function handleOnPageSize(pageSize: number) {
		data.setPageSize(pageSize);
	}

	function handleOnPage(page: number) {
		data.setPage(page);
	}

	function handleOnRefresh() {
		data.refetch();
	}

	function handleOnSort(newSortField: string, newSortOrder: SortOrder) {
		data.setOrder(newSortField, newSortOrder);
	}

	function handleOnValueChange(id: string, value?: string) {
		// Remove the old criterias applied by the multiple operators
		const filter = filters?.find((filter) => filter.id === id);
		if (typeof filter?.operator === 'function') {
			const fieldOperators = filter.operator(filterValues[id]);
			if (fieldOperators) {
				if (isOr(fieldOperators)) {
					data.setCondition &&
						data.setCondition({
							conditions: fieldOperators.operators.map((op) => ({ field: op.field, op: op.op })),
						});
				} else {
					for (const { field, op } of fieldOperators) {
						data.setCondition && data.setCondition({ operator: { field, op } });
					}
				}
			}
		}
		// Remove the value from state
		delete filterValues[id];

		if (value) {
			filterValues[id] = value;
		}

		setFilterValues({ ...filterValues });
	}

	function handleOnOperatorChange(condition: Condition | OrCondition) {
		data.setCondition && data.setCondition(condition);
	}

	return {
		data: data.response ? data.response.rows : [],
		refetch: data.refetch,
		fields: [],
		actions: [],
		error,
		loading: data.loading,
		page: data.page,
		pageSize: data.pageSize,
		totalRecords: data.response ? data.response.records : 0,
		sortField: data.sortField,
		sortOrder: data.sortOrder,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleOnValueChange,
		handleOnOperatorChange,
		handleRemove,
	};
}
