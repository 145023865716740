import { useState, useEffect, useCallback } from 'react';
import { UserManager, WebStorageStateStore, User, UserManagerSettings } from 'oidc-client-ts';
import config from '../shared/config';

const umgrConfig: UserManagerSettings = {
	authority: config.oidc.issuer,
	client_id: config.oidc.clientId,
	redirect_uri: `${window.location.origin}/auth/callback`,
	post_logout_redirect_uri: `${window.location.origin}/auth/exitdoor`,
	response_type: 'code',
	scope: 'openid email phone',
	userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const useAuthService = () => {
	const [userManager, setUserManager] = useState<UserManager | null>(null);
	const [user, setUser] = useState<User | null>(null);
	const [ready, setReady] = useState(false);

	const loadUser = useCallback(
		(user: User) => {
			console.debug('Loaded user:', !!user);
			setUser(user);
		},
		[setUser],
	);

	const unloadUser = useCallback(() => {
		console.debug('Unloaded user');
		setUser(null);
	}, [setUser]);

	useEffect(() => {
		console.debug('Loading UserManager');
		const umgr = new UserManager(umgrConfig);
		setUserManager(umgr);

		umgr.getUser().then((user) => {
			console.debug('Cached user:', !!user);
			if (!user?.expired) {
				setUser(user);
			} else {
				umgr.removeUser();
			}

			setReady(true);
		});

		umgr.events.addUserLoaded(loadUser);
		umgr.events.addUserUnloaded(unloadUser);

		return () => {
			umgr.events.removeUserLoaded(loadUser);
			umgr.events.removeUserUnloaded(unloadUser);
		};
	}, []);

	const login = useCallback(async () => {
		console.debug('Logging into');
		await userManager?.signinRedirect();
	}, [userManager]);

	const handleCallback = useCallback(async () => {
		console.debug('Redirect callback');
		await userManager?.signinRedirectCallback();
	}, [userManager]);

	const logout = useCallback(async () => {
		console.debug('Logging out');
		await userManager?.removeUser();
		const domain = config.oidc.domain;
		const endSessionEndpoint = `https://${domain}/logout`;
		const url = `${endSessionEndpoint}?client_id=${umgrConfig.client_id}&logout_uri=${umgrConfig.post_logout_redirect_uri}`;
		window.location.href = url;
	}, [userManager]);

	return {
		user,
		login,
		handleCallback,
		logout,
		ready,
	};
};

export default useAuthService;
