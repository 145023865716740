/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useNavigate } from 'react-router-dom';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMenu from '../hooks/useMenu';

export default function Breadcrumbs() {
	const navigate = useNavigate();
	const { breadcrumbs } = useMenu();

	return (
		<nav>
			<ol className="flex flex-wrap p-0 mb-0 list-none gap-[8px] max-sm:justify-center">
				<li className="inline-flex items-center">
					<button
						onClick={() => navigate('/')}
						className="font-normal text-xs hover:text-primary group flex items-center gap-2"
					>
						<FontAwesomeIcon icon={faHome} />
						<span className="text-sm">Início</span>
					</button>
				</li>

				{breadcrumbs &&
					breadcrumbs.crumbs.map((crumb) => {
						return (
							<li
								key={crumb.title.replaceAll(' ', '').toLowerCase()}
								className="inline-flex items-center before:content-[''] before:w-1 before:h-1 before:bg-gray-600 before:me-[7px] before:pe-0 before:rounded-[50%] hover:text-primary"
							>
								<button onClick={() => crumb.path && navigate(crumb.path)} disabled={!crumb.path}>
									<span className="text-sm">{crumb.title}</span>
								</button>
							</li>
						);
					})}
			</ol>
		</nav>
	);
}
