import React from 'react';

import WrapperPage from '../WrapperPage';
import useAPISAASAccount from '../../hooks/api/useAPISAASAccount';
import { UilCheckCircle, UilSpinner } from '@iconscout/react-unicons';
import FormGroup from '../../slices/FormGroup';

export default function SubscriptionPage() {
	const { subscription, loading, error } = useAPISAASAccount({
		mustFetchSubscription: true,
	});

	return (
		<WrapperPage title="Gravity Subscription" error={error}>
			<div className="m-4 flex flex-col gap-4">
				{loading && <UilSpinner className="animate-spin" />}
				{subscription && (
					<>
						<FormGroup title={subscription.items.data[0]?.price.product.name}>
							<div className="flex gap-2">
								<UilCheckCircle
									className={`${subscription.status === 'active' ? 'text-success' : 'text-light-extra'}`}
								/>
								<span>{subscription.status}</span>
							</div>
						</FormGroup>
						<FormGroup title="Usage">
							<span>Current usage</span>
						</FormGroup>
						<FormGroup title="Billing Information">
							<span>Billing information</span>
						</FormGroup>
					</>
				)}
			</div>
		</WrapperPage>
	);
}
