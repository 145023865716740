import React, { ReactNode } from 'react';

interface WhiteBoxProps {
	children: ReactNode;
	className?: string | undefined;
}

export default function WhiteBox({ children, className }: WhiteBoxProps) {
	return <div className={`bg-white p-4 shadow-md rounded-md ${className || ''}`.trim()}>{children}</div>;
}
