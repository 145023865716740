import React from 'react';

export type LogLevels = 'INFO' | 'DEBUG' | 'ERROR' | 'WARNING';

interface LogProps {
	logs: string[];
	levels?: Array<LogLevels>;
}

export function summarizeLogs(logs: string[]) {
	const logCount = logs.reduce(
		(acc, log) => {
			const logLevel = log.split(':')[0]; // Extract the log level (INFO, DEBUG, etc.)
			acc[logLevel] = (acc[logLevel] || 0) + 1; // Increment the count for that log level
			return acc;
		},
		{} as Record<string, number>,
	);
	return logCount;
}

const Log: React.FC<LogProps> = ({ logs, levels }) => {
	const getColorForLogLevel = (level: string): string => {
		switch (level) {
			case 'INFO':
				return 'text-blue-500';
			case 'DEBUG':
				return 'text-green-500';
			case 'ERROR':
				return 'text-red-500';
			case 'WARNING':
				return 'text-yellow-500';
			default:
				return 'text-black';
		}
	};

	const parseLogLine = (logLine: string) => {
		const logParts = logLine.split(':');
		const level = logParts[0].trim();
		const message = logParts.slice(1).join(':').trim();
		return { level, message };
	};

	// Filter logs based on levels prop if provided
	const filteredLogs = logs.filter((log) => {
		const { level } = parseLogLine(log);
		// If levels is provided, check if the log's level is included in the levels array
		return !levels || levels.includes(level as LogLevels);
	});

	return (
		<pre className="px-4 bg-gray-100 rounded-md h-96 overflow-y-scroll border-solid border-[1px]">
			{filteredLogs.map((log, index) => {
				const { level, message } = parseLogLine(log);
				return (
					<div key={index} className="whitespace-pre-wrap font-mono">
						<span className={`${getColorForLogLevel(level)} font-bold`}>{level}:</span> {message}
					</div>
				);
			})}
		</pre>
	);
};

export default Log;
