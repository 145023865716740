import { useEffect, useState } from 'react';

import useAPI from './useAPI';
import { useAuth } from '../useAuth';
import { useProfile } from '../useProfile';
import { APIError } from '../../shared/api_errors';
import { PaymentMethodsResponse } from '../../shared/models/saas/account/PaymentMethodsResponse';
import { SetupIntentResponse } from '../../shared/models/saas/account/SetupIntentResponse';
import { SubscriptionResponse } from '../../shared/models/saas/account/SubscriptionResponse';
import { CustomerResponse } from '../../shared/models/saas/account/CustomerResponse';

export class APISAASAccountOptions {
	mustFetchPaymentMethods: boolean = false;
	mustFetchSetupIntent: boolean = false;
	mustFetchSubscription: boolean = false;

	constructor(options?: Partial<APISAASAccountOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPISAASAccount(options?: Partial<APISAASAccountOptions>) {
	const { mustFetchPaymentMethods, mustFetchSetupIntent, mustFetchSubscription } = new APISAASAccountOptions(options);

	const { tenant } = useProfile();
	const { user } = useAuth();

	const api = useAPI(true, user?.access_token, tenant);
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsResponse | undefined>(undefined);
	const [paymentMethodsFetched, setPaymentMethodsFetched] = useState<boolean>(false);

	const [setupIntent, setSetupIntent] = useState<SetupIntentResponse | undefined>(undefined);
	const [customer, setCustomer] = useState<CustomerResponse | undefined>(undefined);

	const [subscription, setSubscription] = useState<SubscriptionResponse | undefined>(undefined);
	const [subscriptionFetched, setSubscriptionFetched] = useState<boolean>(false);

	const [loading, setLoading] = useState(false);
	const [loadingAction, setLoadingAction] = useState(false);
	const [error, setError] = useState<APIError | undefined>();

	async function fetchPaymentMethods() {
		if (!tenant || !customer) {
			setPaymentMethods(undefined);
			setLoading(false);
			return;
		}

		setPaymentMethodsFetched(false);
		setError(undefined);
		setPaymentMethods(undefined);
		setLoading(true);

		try {
			const response = await api.get('/saas/account/payment-methods');
			setError(undefined);
			setPaymentMethodsFetched(true);
			setPaymentMethods(response);
			setLoading(false);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function fetchSetupIntent() {
		if (!tenant || !customer) {
			setLoading(false);
			return undefined;
		}

		setError(undefined);
		setSetupIntent(undefined);
		setLoading(true);

		try {
			const response = await api.get('/saas/account/setup-intent');
			setError(undefined);
			setSetupIntent(response);
			setLoading(false);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				if (error.message === 'Customer not found') return;
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function fetchSubscription() {
		if (!tenant || !customer) {
			setLoading(false);
			return undefined;
		}

		setSubscriptionFetched(false);
		setError(undefined);
		setSubscription(undefined);
		setLoading(true);

		try {
			const response = await api.get('/saas/account/subscription');
			setError(undefined);
			setSubscription(response);
			setLoading(false);
			setSubscriptionFetched(true);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				if (['Subscription not found', 'Subscription not found on customer'].indexOf(error.message) > -1) {
					setSubscription(undefined);
					setSubscriptionFetched(true);
					setError(undefined);
				} else {
					setError(error);
				}
			}
		} finally {
			setLoading(false);
		}
	}

	async function fetchCustomer() {
		if (!tenant) {
			setLoading(false);
			return undefined;
		}

		setError(undefined);
		setCustomer(undefined);
		setLoading(true);

		try {
			const result = await api.get('/saas/account/customer');
			setError(undefined);
			setLoading(false);
			setCustomer(result);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				if (error.status_code === 429) return;
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function updatePaymentMethod(paymentMethodId: string) {
		if (!tenant || !customer) {
			setLoading(false);
			return undefined;
		}

		setError(undefined);
		setLoadingAction(true);

		try {
			await api.put(`/saas/account/payment-methods/${paymentMethodId}/default`);
			setError(undefined);
			setLoadingAction(false);
			fetchPaymentMethods();
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingAction(false);
		}
	}

	async function updatePaymentMethodBySetupIntent(setupIntentId: string) {
		if (!tenant || !customer) {
			setLoading(false);
			return undefined;
		}

		setError(undefined);
		setLoadingAction(true);

		try {
			await api.put(`/saas/account/setup-intent/${setupIntentId}/payment-method/default`);
			setError(undefined);
			setLoadingAction(false);
			fetchPaymentMethods();
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingAction(false);
		}
	}

	async function subscribe() {
		if (!tenant || !customer) {
			setLoading(false);
			return undefined;
		}

		setError(undefined);
		setLoadingAction(true);

		try {
			const response = await api.post('/saas/account/subscribe');
			setError(undefined);
			setSubscription(response);
			setLoadingAction(false);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingAction(false);
		}
	}

	useEffect(() => {
		mustFetchPaymentMethods && fetchPaymentMethods();
		mustFetchSetupIntent && fetchSetupIntent();
		mustFetchSubscription && fetchSubscription();
	}, [user, tenant, customer, mustFetchPaymentMethods, mustFetchSetupIntent, mustFetchSubscription]);

	useEffect(() => {
		const pollCustomer = setInterval(() => {
			if (!customer) {
				fetchCustomer();
			} else {
				clearInterval(pollCustomer);
			}
		}, 2000);

		return () => clearInterval(pollCustomer);
	}, [user, tenant, customer]);

	return {
		paymentMethods,
		paymentMethodsFetched,
		setupIntent,
		subscription,
		subscriptionFetched,
		loading,
		loadingAction,
		error,
		subscribe,
		updatePaymentMethod,
		updatePaymentMethodBySetupIntent,
	};
}
