import React from 'react';
import Overlay from '../components/Overlay';
import Button, { Variant } from '../components/Button';
import { UilCheckCircle, UilExclamationTriangle, UilTimesCircle, UilInfoCircle } from '@iconscout/react-unicons';

export interface AlertBoxProps {
	title: string;
	message: string;
	type?: 'success' | 'warning' | 'danger' | 'info';
	ok?: string;
	onOk?: () => void;
}

export default function Alert({ title, message, type = 'success', ok = 'OK', onOk }: AlertBoxProps) {
	// Map the type to corresponding icons and colors
	let UilIcon;
	let color;

	switch (type) {
		case 'warning':
			UilIcon = UilExclamationTriangle;
			color = 'text-warning';
			break;
		case 'danger':
			UilIcon = UilTimesCircle;
			color = 'text-danger';
			break;
		case 'info':
			UilIcon = UilInfoCircle;
			color = 'text-info';
			break;
		case 'success':
		default:
			UilIcon = UilCheckCircle;
			color = 'text-success';
			break;
	}

	return (
		<Overlay>
			<div className="flex flex-col items-center gap-6">
				<div className="flex items-center gap-2">
					<UilIcon className={color} size="24" />
					<h2 className={`font-bold text-lg ${color}`}>{title}</h2>
				</div>
				<div className="flex flex-col gap-1 items-center">
					{message.split('\n').map((msg) => (
						<p key={msg.replaceAll(' ', '')} className="text-sm">
							{msg}
						</p>
					))}
				</div>
				<div className="mt-4 w-32">
					<Button text={ok} block onClick={onOk} style="outline" variant={type as Variant} />
				</div>
			</div>
		</Overlay>
	);
}
