import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItem } from '../types/MenuItem';
import { useProfile } from './useProfile';
import { TenantRole } from '../context/ProfileContext';
import config from '../shared/config';
import { Breadcrumb, Crumb } from '../types/Breadcrumb';

function useMenu() {
	const location = useLocation();
	const { tenant, saasTenant } = useProfile();
	const [menu, setMenu] = useState<MenuItem[]>([]);
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb | undefined>();

	useEffect(() => {
		const generatedMenu = createMenu(tenant, saasTenant);
		const updatedMenu = setCurrentPathname(generatedMenu, location.pathname);
		setMenu(updatedMenu);

		const breadcrumbs = getCurrentBreadcrumb(updatedMenu, location.pathname);
		setBreadcrumbs(breadcrumbs || undefined);
	}, [location.pathname, tenant, saasTenant]);

	return { items: menu, breadcrumbs };
}

export default useMenu;

// Helper Functions

export function getCurrentBreadcrumb(menu: MenuItem[], pathname: string): Breadcrumb | null {
	// Recursive function to find and return the current breadcrumb
	function findDeepCurrentItem(
		items: MenuItem[],
		breadcrumbs: Crumb[] = [],
		parentPath: string = '',
	): Breadcrumb | null {
		for (const item of items) {
			const fullpath = parentPath ? `${parentPath}${item.to ?? ''}` : item.to ?? '';

			// Create the current crumb for this item
			const currentCrumb: Crumb = {
				path: item.to,
				title: item.title,
			};

			// Add the current crumb to the breadcrumb trail
			const currentBreadcrumbs = [...breadcrumbs, currentCrumb];

			// Check if the current item is active
			if (item.current) {
				// Create the breadcrumb object
				let breadcrumb: Breadcrumb = {
					fullpath,
					crumbs: currentBreadcrumbs,
				};

				// If the current item has nested items, check deeper for current items
				if (item.items) {
					const deeperBreadcrumb = findDeepCurrentItem(item.items, currentBreadcrumbs, fullpath);
					if (deeperBreadcrumb) {
						breadcrumb = deeperBreadcrumb; // Use the deepest breadcrumb found
					}
				}

				// Return the found breadcrumb
				return breadcrumb;
			}
		}

		// Return null if no current item is found
		return null;
	}

	// Start searching the menu for the current items
	const currentBreadcrumb = findDeepCurrentItem(menu);

	// If currentBreadcrumb is found and valid, check for additional segments in the pathname
	if (currentBreadcrumb && hasAdditionalSegment(pathname, currentBreadcrumb.fullpath)) {
		// Add an additional crumb for 'Form' at the end
		currentBreadcrumb.crumbs.push({
			title: 'Form',
		});
	}

	return currentBreadcrumb;
}

// Helper function to check if the route contains additional segments beyond the fullpath
function hasAdditionalSegment(pathname: string, fullpath: string): boolean {
	// Remove the fullpath from the pathname
	const remainingPath = pathname.replace(fullpath, '');

	// Check if there's anything left in the path (excluding leading/trailing slashes)
	return remainingPath.length > 1; // This will be true if there's more than just a '/'
}

function createMenu(tenant?: TenantRole, saasTenant?: TenantRole): MenuItem[] {
	const menu: MenuItem[] = [getHomeMenuItem()];

	if (tenant?.role.startsWith('SAAS')) {
		menu.push(...getSaasMenu());
	}

	if (tenant?.role === 'ISPAdmin') {
		menu.push(...getIspAdminMenu(tenant, saasTenant));
	}

	return menu;
}

function getHomeMenuItem(): MenuItem {
	return {
		id: 'home',
		title: 'Dashboard',
		icon: 'UilEstate',
		to: '/',
	};
}

function getSaasMenu(): MenuItem[] {
	return [
		{
			id: 'services',
			title: 'SAAS',
			separator: true,
		},
		{
			id: 'saas-data',
			title: 'Administration',
			icon: 'UilCog',
			items: [
				{ id: 'isp-import', title: 'ISP Import', to: '/isp-import' },
				{ id: 'audit', title: 'Audit', to: '/audit' },
				{ id: 'isp', title: 'ISP', to: '/isp' },
				{ id: 'users', title: 'Users', to: '/users' },
			],
		},
	];
}

function getIspAdminMenu(tenant: TenantRole, saasTenant?: TenantRole): MenuItem[] {
	const accountMenu = getAccountMenu(tenant);

	const ispAdminMenu: MenuItem[] = [
		{
			id: 'services',
			title: 'ISP',
			separator: true,
		},
		getAdministrationMenu(saasTenant),
		getComponentsMenu(),
		getInfrastructureMenu(),
		{ id: 'customers', title: 'Customers', to: '/customers', icon: 'UilUsersAlt' },
		{ id: 'subscriptions', title: 'Subscriptions', to: '/subscriptions', icon: 'UilTicket' },
	];

	if (config.stripe.on) {
		ispAdminMenu.push(
			{
				id: 'account',
				title: 'SAAS',
				separator: true,
			},
			accountMenu,
		);
	}

	return ispAdminMenu;
}

function getAccountMenu(tenant: TenantRole): MenuItem {
	const items = tenant.subscription
		? [
				{ id: 'account-payment-methods', title: 'Payment Methods', to: '/account/payment-methods' },
				{ id: 'account-subscription', title: 'Subscription', to: '/account/subscription' },
			]
		: [
				{ id: 'account-activate', title: 'Activate', to: '/account/activate' },
				{ id: 'account-payment-methods', title: 'Payment Methods', to: '/account/payment-methods' },
			];

	return {
		id: 'account-menu',
		title: 'Account',
		icon: 'UilBill',
		items,
	};
}

function getAdministrationMenu(saasTenant?: TenantRole): MenuItem {
	const items = [{ id: 'audit', title: 'Audit', to: '/audit' }];

	if (saasTenant) {
		items.push({ id: 'import', title: 'Import', to: '/import' });
	}

	items.push({ id: 'settings', title: 'Settings', to: '/settings' });
	items.push({ id: 'users', title: 'Users', to: '/users' });

	return {
		id: 'administration',
		title: 'Administration',
		icon: 'UilCog',
		items,
	};
}

function getComponentsMenu(): MenuItem {
	const items = [
		{ id: 'service-plan', title: 'Plans', to: '/service/plans' },
		{ id: 'service-speed', title: 'Speeds', to: '/service/speeds' },
		{ id: 'service-type', title: 'Types', to: '/service/types' },
	];

	return {
		id: 'components',
		title: 'Components',
		icon: 'UilFolderOpen',
		items,
	};
}

function getInfrastructureMenu(): MenuItem {
	return {
		id: 'infra',
		title: 'Infrastructure',
		icon: 'UilWifiRouter',
		items: [{ id: 'service-nas', title: 'NAS', to: '/infra/nas' }],
	};
}

function setCurrentPathname(menu: MenuItem[], pathname: string): MenuItem[] {
	return menu.map((item) => {
		const isCurrent = isItemCurrent(item, pathname);
		const updatedItem = { ...item, current: isCurrent };

		if (item.items) {
			updatedItem.items = item.items.map((subItem) => ({
				...subItem,
				current: isItemCurrent(subItem, pathname),
			}));

			updatedItem.current = updatedItem.current || updatedItem.items.some((sub) => sub.current);
		}

		return updatedItem;
	});
}

function isItemCurrent(item: MenuItem, pathname: string): boolean {
	if (!item.to) return false;
	if (item.to === '/') {
		return pathname === '/';
	} else {
		return pathname === item.to || pathname.startsWith(item.to + '/');
	}
}
