import { useEffect, useState } from 'react';
import useAPI from './useAPI';

import { NASConnectionResponse, NASRadiusResponse } from '../../shared/models/infra/NAS';
import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

export class APINASOptions {
	mustFetchPools: boolean = false;
	mustFetchConnectionStatus: boolean = false;
	mustFetchRADIUSConfiguration: boolean = false;
	asSaas: boolean = false;

	constructor(options?: Partial<APINASOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPINASRouterOS(nas_id: string[] | null, options?: Partial<APINASOptions>) {
	const { mustFetchConnectionStatus, mustFetchRADIUSConfiguration, asSaas } = new APINASOptions(options);

	const { tenant, saasTenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, asSaas ? saasTenant || tenant : tenant);

	const [connectionStatus, setConnectionStatus] = useState<NASConnectionResponse[] | undefined>(undefined);
	const [radiusConfiguration, setRADIUSConfiguration] = useState<NASRadiusResponse[] | undefined>(undefined);

	const [loadingConnectionStatus, setLoadingConnectionStatus] = useState<boolean>(false);
	const [loadingRadiusConfigurationCheck, setLoadingRadiusConfigurationCheck] = useState<boolean>(false);
	const [actionLoading, setActionLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	async function checkConnectionStatus() {
		if (!nas_id) {
			setConnectionStatus(undefined);
			setLoadingConnectionStatus(false);
			return;
		}

		try {
			setLoadingConnectionStatus(true);
			// Convert nas_id to array if it's a single string
			const nasIds = Array.isArray(nas_id) ? nas_id : [nas_id];

			// Fetch connection status for each nas_id and progressively update state
			const promises = nasIds.map(async (id) => {
				const result = await api.get(`/infra/nas/${id}/connection/check`);
				setConnectionStatus((prev) => (prev ? [...prev, result] : [result]));
			});

			// Wait for all the requests to finish
			await Promise.all(promises);
		} catch (error) {
			setConnectionStatus(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingConnectionStatus(false);
		}
	}

	async function fetchRADIUSConfiguration() {
		if (!nas_id) {
			setRADIUSConfiguration(undefined);
			setLoadingRadiusConfigurationCheck(false);
			return;
		}

		try {
			setLoadingRadiusConfigurationCheck(true);
			// Convert nas_id to array if it's a single string
			const nasIds = Array.isArray(nas_id) ? nas_id : [nas_id];

			// Fetch RADIUS configuration for each nas_id and progressively update state
			const promises = nasIds.map(async (id) => {
				const result = await api.get(`/infra/nas/${id}/configuration/radius/check`);
				const nasRadiusResponse: NASRadiusResponse = { nas_id: id, response: result };
				setRADIUSConfiguration((prev) => (prev ? [...prev, nasRadiusResponse] : [nasRadiusResponse]));
			});

			// Wait for all the requests to finish
			await Promise.all(promises);
		} catch (error) {
			setRADIUSConfiguration(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingRadiusConfigurationCheck(false);
		}
	}

	async function fetchRADIUSConfigurationUpdate(nas_id: string): Promise<boolean> {
		if (!nas_id) {
			setActionLoading(false);
			return false;
		}

		try {
			setActionLoading(true);
			await api.put(`/infra/nas/${nas_id}/configuration/radius`);
			await fetchRADIUSConfiguration();
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
			return false;
		} finally {
			setActionLoading(false);
		}

		return true;
	}

	function clear() {
		setConnectionStatus(undefined);
		setRADIUSConfiguration(undefined);
		setLoadingConnectionStatus(false);
		setLoadingRadiusConfigurationCheck(false);
		setError(undefined);
	}

	function fetch() {
		mustFetchConnectionStatus && checkConnectionStatus();
		mustFetchRADIUSConfiguration && fetchRADIUSConfiguration();
	}

	useEffect(() => {
		fetch();
	}, [nas_id, mustFetchConnectionStatus, mustFetchRADIUSConfiguration]);

	return {
		connectionStatus,
		radiusConfiguration,
		loadingConnectionStatus,
		loadingRadiusConfigurationCheck,
		actionLoading,
		error,
		clear,
		refresh: fetch,
		fetchRADIUSConfigurationUpdate,
	};
}
