import React, { useEffect, useState } from 'react';
import Input, { InputActionIcon } from './Input';
import AutoComplete, { AutoCompleteItem } from './AutoComplete';
import useCRUDQuery from '../hooks/api/useCRUDQuery';
import { CRUDFormFieldType } from './CRUDFormField';

export interface InputAutoCompleteProps<T> {
	field: CRUDFormFieldType;
	value: T;
	initialValue?: T;
	endpoint: string;
	searchField: string;
	parseKey: (row: T) => string;
	parseDisplay: (row: T) => string;
	onSelect: (selected: T, selected_key: string) => void;
	onClear: () => void;
	actionIcon?: InputActionIcon;
	size?: number;
	quick?: boolean;
}

export default function InputAutoComplete<T>({
	field,
	value,
	endpoint,
	searchField,
	parseKey,
	parseDisplay,
	onSelect,
	onClear,
	actionIcon,
	size = 5,
	quick = false,
}: InputAutoCompleteProps<T>) {
	const [query, setQuery] = useState<string>('');
	const [isFocused, setIsFocused] = useState<boolean>(false);

	const { setCondition, response, loading } = useCRUDQuery<T>(endpoint, {
		initialSortField: searchField,
		initialQuery: {},
		initialPage: 1,
		initialPageSize: size,
		initialSortOrder: 'asc',
		performFetch: isFocused,
		emptyQueryFetch: quick,
	});

	useEffect(() => {
		setCondition({ operator: { field: searchField, op: 'ilike' }, value: query });
	}, [query]);

	function handleAutoCompleteQuery(value: string) {
		setQuery(value);
	}

	function handleAutoCompleteSelect(item: AutoCompleteItem) {
		const selected = response?.rows.find((row) => parseKey(row) === item.key);
		if (!selected) return;

		onSelect(selected, item.key);
		setQuery('');
	}

	function handleAutoCompleteClear() {
		onClear();
		setQuery('');
	}

	function handleFocus() {
		setIsFocused(true);
	}

	function handleBlur() {
		setIsFocused(false);
	}

	const actionIconToUse =
		actionIcon || (value ? { type: 'UilTimes', color: 'text-danger', action: handleAutoCompleteClear } : undefined);

	return (
		<div className="relative" onBlur={handleBlur}>
			<Input
				key={field.property}
				label={field.label}
				placeholder={field.placeholder || 'Start typing to refine records'}
				highlight={field.highlight}
				onFocus={handleFocus}
				value={query || (value && parseDisplay(value)) || ''}
				onChange={(event) => handleAutoCompleteQuery(event)}
				icon={loading ? { type: 'UilSpinner', color: 'text-primary', spin: true } : undefined}
				contentIcon={actionIconToUse}
				full
			/>
			{response && isFocused && (
				<AutoComplete
					items={response.rows.map((item) => ({
						key: parseKey(item),
						display: parseDisplay(item),
					}))}
					onSelect={handleAutoCompleteSelect}
				/>
			)}
		</div>
	);
}
